<template>
<div>
 <van-nav-bar
      class="backBox"
      title="我的账单"
      right-text=""
      left-arrow
      @click-left="onClickLeft"
    />

  <div class="page">

    <div class="mainWrap">
      <div class="query_box">
        <div class="query_box_item">
          <span class="query_box_item_tit">车牌号码</span>
          <div class="query_box_item_right">
            <div class="section">
              <span class="selPlateNum" @click="openVhPopup">{{
                selVhVal ? selVhVal : '选择车牌号码'
              }}</span>
              <img
                src="../../../assets/img/rightjt@2x.png"
                class="rightIcon"
              />
              <van-popup
                v-model="showVhPopup"
                @close="onVhClose"
                position="bottom"
              >
                <van-picker
                  :columns="vhColumns"
                  @change="onVhChange"
                  @confirm="onVhConfirm"
                  @cancel="onVhCancel"
                  show-toolbar
                  title="选择车牌号码"
                  visible-item-count="5"
                  default-index="0"
                />
              </van-popup>
            </div>
          </div>
        </div>

        <div class="query_box_item">
          <span class="query_box_item_tit">时间范围</span>
          <div class="query_box_item_right" @click="openDate">
            <div class="section">
              <span class="showCommon">
                {{ currentDateStartFormat }}-{{ currentDateEndFormat }}</span
              >

              <img
                src="../../../assets/img/rightjt@2x.png"
                class="rightIcon"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="list_box">
        <van-tabs v-model="active" @change="onChange">
          <van-tab title="停车缴费">
            <van-pull-refresh
              v-model="refreshing"
              @refresh="onRefresh"
              success-text="刷新成功"
              :disabled="errorFlag"
            >
              <van-list
                v-model="loading"
                :finished="finished"
                :immediate-check="false"
                finished-text="没有更多了"
                :error.sync="error"
                error-text="请求失败，点击重新加载"
                @load="onLoad"
              >
                <div
                  class="group_recharge"
                  v-for="item in parkPayRecordList"
                  :key="item.id"
                >
                  <div class="itemBox" @click="openDetail(item, 1)">
                    <div class="mainBox">
                      <div class="mainBoxLeft">
                        <img
                          src="../../../assets/img/paymentRecordleft@2x.png"
                          class="mainBoxLeftIcon"
                        />
                        <div class="mainBoxLeftCenter">
                          <span class="mainBoxLeftCenterNum">{{ item.plateNum }}</span>
                          <span class="mainBoxLeftCenterType">停车支付</span>
                          <span class="mainBoxLeftCenterDate">{{
                            formattedDate(item.date)
                          }}</span>
                        </div>
                      </div>
                      <div class="mainBoxRight">
                        <span class="mainBoxRightMoney">-{{ item.money / 100 }}元</span>
                      </div>
                    </div>
                    <div
                      class="lineline"
                      v-if="objectArray.length - 1 !== index"
                    ></div>
                  </div>
                </div>
              </van-list>
            </van-pull-refresh>
            <!-- 空状态 -->
            <van-empty
              v-if="parkPayRecordList.length == 0"
              description="暂无匹配数据"
            />
            <van-empty
              v-if="parkPayRecordList.length !== 0 && isError"
              image="error"
              description="查询异常"
            />
          </van-tab>
          <van-tab title="月卡续费">
            <van-pull-refresh
              v-model="refreshingMonth"
              @refresh="onRefreshMonth"
              success-text="刷新成功"
              :disabled="errorFlagMonth"
            >
              <van-list
                v-model="loadingMonth"
                :finished="finishedMonth"
                :immediate-check="false"
                finished-text="没有更多了"
                :error.sync="errorMonth"
                error-text="请求失败，点击重新加载"
                @load="onLoadMonth"
              >
                <div
                  class="group_recharge"
                  v-for="item in monthPayRecordList"
                  :key="item.id"
                >
                  <div class="itemBox" @click="openDetail(item, 2)">
                    <div class="mainBox">
                      <div class="mainBoxLeft">
                        <img
                          src="../../../assets/img/fapiaojilu2@2x.png"
                          class="mainBoxLeftIcon"
                        />
                        <div class="mainBoxLeftCenter">
                          <span class="mainBoxLeftCenterNum">月卡金额</span>
                          <span class="mainBoxLeftCenterType">充值分组</span>
                          <span decode="true" class="mainBoxLeftCenterDate">{{
                            formattedDate(item.date)
                          }}</span>
                        </div>
                      </div>
                      <div class="mainBoxRight">
                        <span class="mainBoxRightMoney">{{ item.money }}元</span>
                      </div>
                    </div>
                    <div
                      class="lineline"
                      v-if="monthPayRecordList.length - 1 !== index"
                    ></div>
                  </div>
                </div>
              </van-list>
            </van-pull-refresh>
            <!-- 空状态 -->
            <van-empty
              v-if="monthPayRecordList.length == 0"
              description="暂无匹配数据"
            />
            <van-empty
              v-if="monthPayRecordList.length !== 0 && isError"
              image="error"
              description="查询异常"
            />
          </van-tab>
        </van-tabs>
      </div>
    </div>
    <!-- 时间选择器 -->
    <date-select
      :show-date="showDatePopup"
      @get-on-close="getOnCloseDate"
      @doneHaveSelDate="doneHaveSelDate"
    ></date-select>
    <!-- 分页 -->
    <!-- <Pagination
      :total="pageOption.total"
      :page_no="pageOption.page_no"
      :page_size="pageOption.page_size"
      @page-change="handlePageChange"
    ></Pagination> -->
  </div>
  </div>
</template>
<script>
import DateSelect from '../../../components/DateSelect.vue'
// import Pagination from '../../../components/Pagination.vue'
import * as common from '../../../api/common'
import * as api from '../../../api/api'
import { Toast } from 'vant'
// import moment from 'moment'
export default {
  // components: { DateSelect, Pagination },
  components: { DateSelect },
  data () {
    return {
      tokenInfoObj: {},
      active: 0,

      show: false,

      objectArray: [{
        id: 0,
        money: '22',
        groupName: '银湖分组',
        date: '2022/12/12 12:12:12',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      },
      {
        id: 1,
        money: '25',
        groupName: '银湖分组2',
        date: '2023-11-11 13:13:13',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      },
      {
        id: 2,
        money: '25',
        groupName: '银湖分组2',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png',
        plateNum: '浙A88899'
      }
      ],
      objectArray2: [{
        id: 0,
        money: '22',
        groupName: '银湖缴费分组',
        date: '2022-12-12 12:12',
        src: '../../../assets/img/rightjt.png'
      },
      {
        id: 1,
        money: '25',
        groupName: '银湖缴费分组',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png'
      },
      {
        id: 2,
        money: '25',
        groupName: '银湖缴费分组',
        date: '2023-11-11 13:13',
        src: '../../../assets/img/rightjt.png'
      }
      ],
      vhColumns: ['全部', '浙A12345', '中国', '巴西', '日本'],
      index: 0,
      array2: ['今天', '明天', '前天', '巴西', '日本'],
      index2: 0,
      recentDates: '',
      lastAmonth: '',
      lastThreeMonth: '',
      plateNum: '',
      user_info: {},
      token: '',
      parkPayRecordList: [
        {
          id: 0,
          money: '22',
          groupName: '银湖分组',
          date: '2022-12-12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        },
        {
          id: 1,
          money: '25',
          groupName: '银湖分组2',
          date: '2023/11/11 13:13:13',
          in_time: '2023-11-11 13:13:13',
          out_time: '2023-11-11 13:13:!3',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899',
          parked_time: {
            seconds: '99小时'
          }
        }

      ],
      monthPayRecordList: [
        {
          id: 0,
          money: '22',
          groupName: '银湖分组',
          date: '2022-12-12 12:12:12',
          src: '../../../assets/img/rightjt.png',
          plateNum: '浙A88899'
        }
      ],
      isError: false,
      showVhPopup: false,
      selVhVal: '',
      showDatePopup: false,
      currentDateStartFormat: common.fmtDate(new Date(), 'yyyy-MM-dd'),
      currentDateEndFormat: common.fmtDate(new Date(), 'yyyy-MM-dd'),
      pageOption: {
        page_no: 1,
        page_size: 10,
        total: 0
      },
      loading: false,
      finished: false,
      refreshing: false,
      error: false,
      errorFlag: false,
      loadingMonth: false,
      finishedMonth: false,
      refreshingMonth: false,
      errorMonth: false,
      errorFlagMonth: false

    }
  },
  mounted () {
    this.queryTab()
  },
  computed: {

  },
  methods: {
    formattedDate (dateString) {
      // const localDate = moment.utc(dateString).local()
      // return localDate.format('YYYY/MM/DD HH:mm:ss')
      return dateString.replace(/-/g, '/') //
    },
    formatDate (date) {
      return common.fmtDate(date, 'yyyy/MM/dd hh:mm:ss')
    },
    handlePageChange (page) {
      console.log('New page number:', page)
      this.queryTab()
    },
    doneHaveSelDate (date1, date2) {
      this.currentDateStartFormat = date1
      this.currentDateEndFormat = date2
      this.showDatePopup = false
      this.queryParkPayRecord()
    },
    getOnCloseDate () {
      this.showDatePopup = false
    },
    openVhPopup () {
      this.showVhPopup = true
    },
    onVhClose () {

    },
    onVhChange () {

    },
    onVhConfirm (value) {
      this.selVhVal = value
      this.showVhPopup = false
      this.queryParkPayRecord()
    },
    onVhCancel () {
      this.showVhPopup = false
    },
    openDate () {
      this.showDatePopup = true
    },
    queryTab () {
      if (this.active === 0) {
        this.queryParkPayRecord()
      } else {
        this.queryMonthPayRecord()
      }
    },
    // 切换Tab
    onChange () {
      console.log(this.active)
      this.pageOption.page_no = 1
      this.queryTab()
    },
    openDetail (item, type) {
      const encodedItem = encodeURIComponent(JSON.stringify(item))

      this.$router.push({ name: 'myBillDetail', params: { type: type, item: encodedItem } })
      // this.$router.push({ path: '/myBillDetail', query: { type: type, data: item } })
    },
    // 查询停车缴费
    queryParkPayRecord () {
      // todo 参数完善
      // todo 微信授权之后应该换取token保存相应的userinfo信息
      const para = {
        customer_id: 148,
        page_no: this.pageOption.page_no,
        page_size: this.pageOption.page_size,
        begin_time: this.currentDateStartFormat === '' ? '' : this.currentDateStartFormat + ' 00:00:00',
        end_time: this.currentDateEndFormat === '' ? '' : this.currentDateEndFormat + ' 23:59:59',
        vh_p_number: this.selVhVal === '全部' ? '' : this.selVhVal
      }
      api
        .axiosRequest(api.urlList.parkRecord.park, para)
        .then((res) => {
          Toast.clear()
          if (res && res.code === common.resCode.OK) {

          } else {
            this.pageOption.total = 100
          }
        })
        .catch((e) => {
          console.log('error log:', e)
        })
    },
    // 月卡续费
    queryMonthPayRecord () {
      console.log('查询月卡缴费')
      this.pageOption.total = 24
    },
    onClickLeft () {
      this.$router.go(-1)
    },
    // 停车
    onRefresh () {
      this.parkPayRecordList = []
      this.finished = false
      this.loading = true
      this.onLoad()
    },
    onLoad () {
      // 监听到即执行
      console.log('滑到底了')

      //   再次调用 查询 下一页数据
      if (this.refreshing) {
        // 下拉刷新
        this.pageOption.page_no = 1
        this.queryParkPayRecord()
      } else {
        if (this.errorFlag) {
          // 说明上次加载失败 现在重新加载
          this.pageOption.page_no = 1
          this.errorFlag = false
          this.queryParkPayRecord()
          return false
        }
        // 触底加载
        if (this.parkPayRecordList.length >= this.pageOption.total) {
          this.finished = true
        } else {
          this.pageOption.page_no = this.pageOption.page_no + 1
          this.queryParkPayRecord()
        }
      }
    },
    // 月卡
    onRefreshMonth () {
      this.monthPayRecordList = []
      this.finishedMonth = false
      this.loadingMonth = true
      this.onLoadMonth()
    },
    onLoadMonth () {
      // 监听到即执行
      console.log('滑到底了')

      //   再次调用 查询 下一页数据
      if (this.refreshingMonth) {
        // 下拉刷新
        this.pageOption.page_no = 1
        this.queryMonthPayRecord()
      } else {
        if (this.errorFlagMonth) {
          // 说明上次加载失败 现在重新加载
          this.pageOption.page_no = 1
          this.errorFlagMonth = false
          this.queryMonthPayRecord()
          return false
        }
        // 触底加载
        if (this.monthPayRecordList.length >= this.pageOption.total) {
          this.finishedMonth = true
        } else {
          this.pageOption.page_no = this.pageOption.page_no + 1
          this.queryMonthPayRecord()
        }
      }
    }

  }
}
</script>
<style lang="scss" scoped>
.page {

}
.mainWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #f8f7fc;
  height: 100vh;
  padding: 0.16rem 0;
  overflow: hidden;
  .rightIcon {
    width: 0.08rem;
    height: 0.14rem;
    margin: 0.03rem 0 0.03rem 0.05rem;
  }
  .lineline {
    height: 0.01rem;
    background-color: #e2e2e2;
    margin-top: 0.07rem;
    margin-bottom: 0.07rem;
  }
  .query_box {
    width: 3.43rem;
    // height: 5rem;
    display: flex;
    flex-direction: column;
    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      /* width: 686rpx; */
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .selPlateNum{
            font-size: 0.14rem;
          }
        }
      }
    }

    .query_box_item {
      background-color: rgba(255, 255, 255, 1);
      position: relative;
      // height: 2.5rem;
      flex-direction: row;
      display: flex;
      justify-content: space-between;
      padding: 0.09rem 0.16rem;
      .query_box_item_tit {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 1);
        font-size: 0.16rem;
        font-family: PingFangSC-Regular;
        font-weight: normal;
        text-align: left;
        white-space: nowrap;
        // line-height: 1.375rem;
      }
      .query_box_item_right {
        /* width: 84rpx; */
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        margin: 0.01rem 0;
        .section {
          display: flex;
          /* width: 90rpx; */
          justify-content: space-between;
          align-items: center;
          .showCommon{
            font-size: 0.14rem;
          }
          .rightIcon {
            width: 0.08rem;
            height: 0.14rem;
            margin: 0.03rem 0 0.03rem 0.05rem;
          }
        }
        .image_4-1 {
          position: absolute;
          left: 0;
          top: -0.01rem;
          width: 3.42rem;
          height: 0.01rem;
          background-color: #f0f0f0;
        }
      }
    }
  }

  .list_box {
    background-color: rgba(255, 255, 255, 1);
    width: 3.43rem;
    align-self: center;
    margin-top: 0.16rem;
    display: flex;
    flex-direction: column;
    padding: 0.08rem 0.01rem 0.01rem 0;
    overflow-y: auto;
    .itemBox {
      width: 3.25rem;
      flex-direction: column;
      display: flex;
      justify-content: space-between;
      margin: 0.04rem 0.07rem 0 0.1rem;
      .mainBox {
        width: 3.25rem;
        flex-direction: row;
        display: flex;
        justify-content: space-between;
        /* margin: 8rpx 14rpx 0 20rpx; */
        .mainBoxLeft {
          /* width: 280rpx; */
          flex-direction: row;
          display: flex;
          justify-content: space-between;
          .mainBoxLeftIcon {
            width: 0.2rem;
            height: 0.16rem;
            margin: 0.03rem 0 0.43rem 0;
          }
          .mainBoxLeftCenter {
            display: flex;
            flex-direction: column;
            margin-left: 0.06rem;
            .mainBoxLeftCenterNum {
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 0.16rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.22rem;
              margin-right: 0.39rem;
            }
            .mainBoxLeftCenterType {
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              margin: 0.04rem 0.55rem 0 0;
            }

            .mainBoxLeftCenterDate {
              overflow-wrap: break-word;
              color: rgba(153, 153, 153, 1);
              font-size: 0.12rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.17rem;
              margin-top: 0.02rem;
            }
          }

        }
        .mainBoxRight {
            margin-bottom: 0.19rem;
            display: flex;
            flex-direction: column;
            .mainBoxRightMoney {
              overflow-wrap: break-word;
              color: rgba(51, 51, 51, 1);
              font-size: 0.16rem;
              font-family: PingFangSC-Regular;
              font-weight: normal;
              text-align: left;
              white-space: nowrap;
              line-height: 0.22rem;
              margin-left: 0.17rem;
            }
          }
      }
    }
  }
}
</style>
